<template>
  <div class="onboarding-form">
    <div class="pb-5 pt-lg-0 pt-3">
      <div class="row">
        <form
          @submit.prevent="submit"
          class="form animate__animated animate__backInUp"
          autocomplete="false"
        >
          <div class="pb-13 pt-lg-0 pt-5">
            <h3
              class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
            >
              Invite An Administrator <small>(Step 2 of 10)</small>
            </h3>
            <p class="text-muted font-weight-bold font-size-h4">
              Enter your email to invite an admin to help you complete setup
            </p>
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-solid h-auto py-5 px-6 rounded-lg font-size-h6"
              :class="{ 'is-invalid': $v.email.$error }"
              v-model.trim="$v.email.$model"
              placeholder="Adminstrator Email"
              autocomplete="off"
            />
            <template v-if="$v.email.$error">
              <span class="form-text text-danger" v-if="!$v.email.required">
                e-mail address is required.
              </span>
              <span class="form-text text-danger" v-if="!$v.email.email">
                e-mail address is not properly formed.
              </span>
            </template>
          </div>
          <div class="form-group d-flex flex-wrap pb-lg-0">
            <router-link to="/onboarding/company-setup" v-slot="{ navigate }">
              <button
                type="button"
                class="btn btn-light-danger font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                @click="navigate"
              >
                Cancel
              </button>
            </router-link>

            <button
              :disabled="loading == true ? true : false"
              type="submit"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              <b-spinner
                v-if="loading == true"
                variant="light"
                label="Spinning"
              ></b-spinner>
              <span v-if="loading == false"
                >Invite Administrator<i class="fas fa-paper-plane ml-2"></i
              ></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//import Swal from "sweetalert2";
//import Multiselect from "vue-multiselect";
import CompanyService from "@/core/services/company.service.js";
import SignUpService from "@/core/services/signup.service.js";
import { mapGetters, mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      show: true,
      email: "",
      loading: false
    };
  },
  validations: {
    email: { required, email }
  },
  components: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {
    this.getActionParties();
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        SignUpService.sendInvite({
          email: this.email,
          role_id: 1,
          setup: true
        })
          .then(() => {
            try {
              this.nextStep();
            } catch (error) {
              console.log(error);
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
              this.loading = false;
            }
          })
          .catch(error => {
            if (error.data) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            this.loading = false;
          });
      }
    },

    nextStep() {
      var data = {
        previous_onboarding_stage: 1,
        onboarding_stage: 2,
        onboarding_complete: false
      };
      this.loading = true;
      CompanyService.setOnboardingStage(data)
        .then(() => {
          try {
            this.$router.push({ name: "onboarding-invitation-sent" });
          } catch (error) {
            console.log(error);
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
            this.loading = false;
          }
        })
        .catch(error => {
          if (error.data) {
            this.makeToast("danger", error.statusText, error.data.message);
          } else {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
          }
          this.loading = false;
        });
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
